<template>
  <div class="col-12 g">
    <div class="card">
        <div class="card-header">
            
        </div>
        <div class="card-body">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-sm">
                    <thead>
                        <th>
                            {{ lang('id') }}
                        </th>
                        <th>
                            {{ lang("date") }}
                        </th>
                        <th>
                            {{ lang("devices-count") }}
                        </th>
                        <th>
                            {{ lang("total") }}
                        </th>
                        <th>
                            {{ lang("status") }}
                        </th>
                        <th>
                            {{ lang("options") }}
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="order in orders" :key="order._id">
                            <td>
                                {{ order.order_id }}
                            </td>
                            <td>
                                {{ order.date.split(" ")[0] }}
                            </td>
                            <td>
                                {{ order.devices.length }}
                            </td>
                            <td>
                                {{ order.amount }} {{ lang('sa') }}
                            </td>
                            <td>
                                <span :class="'btn btn-sm ' + order.status.toString().replace('0', 'btn-warning').replace('1', 'btn-success').replace('2', 'btn-danger')">
                                    {{ lang(`status-${order.status}`) }}
                                </span>
                            </td>
                            <td>
                                <button @click="print(order)" v-if="order.status == 1" class="btn btn-sm btn-secondary"><i class="fa fa-print"></i> {{ lang("print") }}</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            orders: [],
            user: JSON.parse(localStorage.getItem('user'))
        }
    },
    created(){
        var g = this;
        $.post(api + '/user/devices/subscribe/orders', {
            jwt: g.user.jwt,
        }).then(function(r){
            g.orders = r.response
        })
    },
    methods: {
        print(order){
            var g = this;
            var win = window.open("", "bioraw", "width=400,height=600");
            order.sada = true;
            var dd = "";
            order.devices.forEach(function(a){
                dd = dd + `
                <tr>
                    <td>${a}</td>
                    <td>400</td>
                    <td>${order.callbacks.includes(a) ? '100' : '--'}</td>
                    <td>${order.callbacks.includes(a) ? '500' : '400'}</td>
                </tr>
                `
            })
            win.document.write(`
            <style>
                table{
                width: 100%;
                }
                table, td, th {
                border: 1px solid;
                }
                table {
                width: 100%;
                border-collapse: collapse;
                }
            </style>
            <div class="card-body text-center" dir='rtl'>
            <p>
                المملكة العربية السعودية
                    <br>
                    ${order.sada ? `مؤسسة الصدى لتقنية المعلومات
                    <br>
                    سجل تجاري: 1128183340
                    <br>
                    جوال: 0580200670
                    <br>
                    الرقم الضريبي: 310458863500003` : `نظام التحضير الذكي`}
            </p>
            <table class="table table-hover table-bordered">
            <tbody>
                <tr>
                    <th>رقم العملية / رقم الطلب</th>
                    <th>${order.order_id}</th>
                </tr>
                <tr>
                    <th>اجمالي المبلغ الخاضع للضريبة</th>
                    <th>${order.original} ريال</th>
                </tr>
                <tr>
                    <th>ضريبة القيمة المضافة 15%</th>
                    <th>${order.tax} ريال</th>
                </tr>
                <tr>
                    <th>اجمالي المبلغ مع الضريبة 15%</th>
                    <th>${order.amount} ريال</th>
                </tr>
                <tr>
                    <th>تاريخ الإشتراك</th>
                    <th>${order.date}</th>
                </tr>
                <tr>
                    <th>مدة الإشتراك</th>
                    <th>عام من تاريخ الاشتراك</th>
                </tr>
                <tr>
                    <th>اسم العميل</th>
                    <th>${g.user.name}</th>
                </tr>
                <tr>
                    <th>الجوال</th>
                    <th>${g.user.phone}</th>
                </tr>
                </tbody>
            </table>
            <br>
            <table class="table table-hover table-bordered">
                <thead>
                    <th>
                        الجهاز
                    </th>
                    <th>
                        التكلفة
                    </th>
                    <th>
                        Callback
                    </th>
                    <th>الاجمالي</th>
                </thead>
                <tbody>
                ${dd}
                </tbody>
            </table>
            </div>
            `);
        }
    }
}
</script>

<style>

</style>